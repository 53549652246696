import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './stylesheets/main.scss';
import Header from './components/Header';
import Footer from './components/Footer';
import Segment from './containers/Segment';
import Home from './containers/Home';
import Nbo from './containers/Nbo';

function App() {
  return (
    <Router>
      <div className="wrapper theme-light">
        <Header />
        <main className="main">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/p4d" element={<Segment />} />
            <Route path="/nbo" element={<Nbo />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
