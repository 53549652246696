import React, { useState } from 'react';
import CardItem from '../../components/Card';

function Segment() {
  const [files, setFiles] = useState('');

  const handleChange = (e) => {
    const fileReader = new FileReader();
    fileReader.readAsText(e.target.files[0], 'UTF-8');
    fileReader.onload = (e) => {
      setFiles(e.target.result);
    };
  };

  const profiledConstants = eval(files);

  return (
    <>
      <h1>Test vignette P4D</h1>
      <div className="container-segment">
        <label htmlFor="upload">Séléction du fichier à tester</label>
        <input
          id="upload"
          name="upload"
          type="file"
          onChange={handleChange}
          accept=".json"
        />
        <div className="page-segment">
          {profiledConstants ? (
            <>
              <div>
                <h3>Nombre de profils : {profiledConstants.length}</h3>
                {/* <a href="/export" p4d={profiledConstants}>
                  export
                </a> */}
                {/* <SegmentExport p4d={profiledConstants} /> */}
                <div className="grid">
                  {profiledConstants.map((profiledConstant, id) => (
                    <div key={id}>
                      <CardItem data={profiledConstant} />
                    </div>
                  ))}
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
}

export default Segment;
