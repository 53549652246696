import React from 'react';
import { Link,  } from 'react-router-dom';

function Home() {
  return (
    <div className='container-home'>
      <header>
        <section>
          <h1>Home</h1>
        </section>
        <nav>
          <ul>
            <li>
              <Link to="/p4d">Test vignette P4D</Link>
            </li>
            <li>
              <Link to="/nbo">Test link Nbo</Link>
            </li>
          </ul>
        </nav>
      </header>
    </div>
  );
}


export default Home;
