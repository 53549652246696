import React from 'react';

function CardItem(data) {
  const infos = data;

  return (
    <div className="containerSegment">
      <div className="headSegment">{infos.data.segType}</div>
      <div className="infoGridSegment">
        <div className="infoSegment">
          <h3>Desktop</h3>
          <div className="imgSegment">
            <img src={infos.data.pictureDesktop.URLImage} alt="visuel" />
          </div>
        </div>
        <div className="infoSegment">
          <h3>Mobile</h3>
          <div className="imgSegment">
            <img src={infos.data.pictureMobile.URLImage} alt="visuel" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardItem;
